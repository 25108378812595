import React from "react"
import Article from "../components/Article"
import i18nHOC from "../components/i18nHOC"
import Paragraph from "../components/Paragraph"
import ImageWithTitle from "../components/ImageWithTitle"
import { graphql } from "gatsby"
import ImageGrid from "../components/ImageGrid"
import SinglePortraitImageWithTitle from "../components/SinglePortraitImageWithTitle"

function saltbush({ data, t }) {
  return <Article coverImage={{ image: data.coverImage, title: t("items.saltbush.coverCap")}}
                  title={t("items.saltbush.title")}>
    {/*todo ссылка*/}
    <Paragraph>{t("items.saltbush.p1")}</Paragraph>
    <ImageGrid>
      <ImageWithTitle style={{gridRow: '1 / span 2'}} fixed={data.grid11.childImageSharp.fixed} title={t("items.saltbush.cap1")}/>
      <ImageWithTitle fluid={data.grid12.childImageSharp.fluid} title={t("items.saltbush.cap2")}/>
      <ImageWithTitle fluid={data.grid13.childImageSharp.fluid} title={t("items.saltbush.cap3")}/>
    </ImageGrid>
    <ImageWithTitle fluid={data.saltbush5.childImageSharp.fluid}
                    title={t("items.saltbush.cap4")}/>
    <ImageGrid>
      <ImageWithTitle fluid={data.grid21.childImageSharp.fluid} title={t("items.saltbush.cap5")}/>
      <ImageWithTitle fluid={data.grid22.childImageSharp.fluid} title={t("items.saltbush.cap6")}/>
      <ImageWithTitle fluid={data.grid23.childImageSharp.fluid} title={t("items.saltbush.cap7")}/>
      <ImageWithTitle fluid={data.grid24.childImageSharp.fluid} title={t("items.saltbush.cap8")}/>
    </ImageGrid>
    <SinglePortraitImageWithTitle fluid={data.saltbush10.childImageSharp.fluid}
                    title={t("items.saltbush.cap9")} />
  </Article>
}

export default i18nHOC(saltbush);

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "saltbush/IMG_9848 (1).jpg" }) {
        ...CoverImage
    },
    saltbush1: file(relativePath: { eq: "saltbush/cueillette-4 (1).jpg" }) {
      ...FluidArticleImage
    },
    grid11: file(relativePath: { eq: "saltbush/лебеда петриашвили.JPG" }) {
      childImageSharp {
        fixed(height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    grid12: file(relativePath: { eq: "saltbush/orache on chavchavadze.JPG" }) {
      ...FluidArticleImage
    },
    grid13: file(relativePath: { eq: "saltbush/лебеда мтацминда.JPG" }) {
      ...FluidArticleImage
    },
    saltbush5: file(relativePath: { eq: "saltbush/IMG_7552.jpg" }) {
      ...FluidArticleImage
    },
    grid21: file(relativePath: { eq: "saltbush/sorrel on mtatzminda.JPG" }) {
      ...FluidArticleImage
    },
    grid22: file(relativePath: { eq: "saltbush/DSC08003.JPG" }) {
      ...FluidArticleImage
    },
    grid23: file(relativePath: { eq: "saltbush/cinquefoils on mtatsminda.JPG" }) {
      ...FluidArticleImage
    },
    grid24: file(relativePath: { eq: "saltbush/DSC_1675.JPG" }) {
      ...FluidArticleImage
    },
    saltbush10: file(relativePath: { eq: "saltbush/DSC07976.JPG" }) {
      ...FluidArticleImage
    },
  }
`